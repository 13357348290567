import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import "styles/global.medangle.style.css"

function GlobalFooter(props) {

return(
  <footer>
  <div className="columns is-centered">
    <div className="column is-12-mobile is-12-tablet is-10-desktop">
      <hr className="medangle-gradient-hairline" />
    </div>
  </div>
  <div className="columns is-multiline is-centered">
    <div className="column is-3-desktop is-12-tablet footer-padding">
      <a href="/">
        <img
          width={128}
          src="https://medangle.com/assets/medangle-logo-v5-05e0d2d44d719d47c0c17976cc5d2b7b89ad681d5f36b90839f4b5d333160b12.svg"
        />
        <h1 className="is-size-3 has-text-weight-bold has-text-white">
          MedAngle
        </h1>
        <p className="has-text-white">
          MedAngle is the future of medical education{" "}
          <br className="is-hidden-mobile" /> and medical intelligence.
        </p>
      </a>{" "}
    </div>
    <div className="column is-7-desktop is-12-tablet footer-padding">
      <div className="columns">
        <div className="column is-12-mobile is-4-tablet is-4-desktop">
          <h2 className="has-text-weight-bold is-size-5">MedAngle</h2>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/about">About</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/team">Team</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/vision">Vision</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/news">News</a>
          </p>
        </div>
        <div className="column is-12-mobile is-4-tablet is-4-desktop">
          <h2 className="has-text-weight-bold is-size-5">Product</h2>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/features">Features</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/reviews">Reviews</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/practice-quizzes">Quizzes</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/brand-assets">Brand</a>
          </p>
        </div>
        <div className="column is-12-mobile is-4-tablet is-4-desktop">
          <h2 className="has-text-weight-bold is-size-5">Get Involved</h2>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/careers">Careers</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/parents">Parents</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/cap/capplications">Ambassadors</a>
          </p>
          <p className="has-text-white has-text-weight-normal is-size-5">
            <a href="/join-the-team">Join The Team</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="columns is-centered">
    <div className="column is-12-mobile is-12-tablet is-10-desktop">
      <hr className="medangle-gradient-hairline" />
    </div>
  </div>
  <div className="columns is-centered">
    <div className="column is-12-mobile is-6-tablet is-5-desktop copyright-links">
      <span className="has-text-gray is-size-5">© MedAngle 2021</span>
      <span className="has-text-gray is-size-5">
        <a href="/terms-of-service">Terms</a>
      </span>
      <span className="has-text-gray is-size-5">
        <a href="/privacy-policy">Privacy</a>
      </span>
    </div>
    <div className="column is-12-mobile is-6-tablet is-5-desktop footer-social-icons is-pulled-right">
      <a target="_blank" href="https://facebook.com/medangledotcom">
        <img src="https://medangle.com/assets/social-media/facebook-3224a994b72a8b98b1c3f334c1949c8449c26f711b2d4cbc52962b34327503ac.svg" />
      </a>
      <a target="_blank" href="https://www.instagram.com/med_angle/">
        <img src="https://medangle.com/assets/social-media/instagram-f470c67001b273566c34ee19d73370c3e7c77b71eca374ec4c3b492154ebb841.svg" />
      </a>
      <a target="_blank" href="https://twitter.com/med_angle">
        <img src="https://medangle.com/assets/social-media/twitter-204101b0f366c7f10297ba4fe29a32405279f6906ee66c11d0d08f398b09c493.svg" />
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/medangle/">
        <img src="https://medangle.com/assets/social-media/linkedin-09a7d6204212458cfcaef5c732cf130276ffbb4d85605de1477ea1d74881a38f.svg" />
      </a>
      <a
        target="_blank"
        className="is-hidden-mobile"
        href="https://www.snapchat.com/add/teammedangle"
      >
        <img src="https://medangle.com/assets/social-media/snapchat-23cd3c5495c2a4d13453b3da1b3e481466de56db57beac88af7eb21baf6c9284.svg" />
      </a>
      <a
        target="_blank"
        href="https://www.youtube.com/channel/UCyETfbvw_9woPRrCfqZSeOw/"
      >
        <img src="https://medangle.com/assets/social-media/youtube-34222daa658d11e6ae1ac4858ce724dbe425e112331f028a7f32fdd6514d7387.svg" />
      </a>
    </div>
  </div>
  <div className="columns is-centered">
    <div className="column is-12-mobile is-12-tablet is-10-desktop designed-with-love">
      <p>
        Designed with
        <img src="https://medangle.com/assets/icons/heart-9aa6f778b39312b1236c3ace6cda69bb2b23ef3b5868a60be7a42699e145da82.svg" />{" "}
        <span>
          {" "}
          in
          <img src="https://medangle.com/assets/icons/pakflag-795e3703129e40934a4e723de81d15322cff3e6105e0b465221dc10ea092fa5b.svg" />
        </span>{" "}
        <span>
          and
          <img src="https://medangle.com/assets/icons/usaflag-82ac0d712e12748642f658692f208b338383cee9d8eb49c27b7c601dfcf8529b.svg" />
        </span>
      </p>
    </div>
  </div>
</footer>
  );
}

  export default GlobalFooter
  