/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import GlobalFooter from  "components/medangle/GlobalFooter"
import GlobalNavigation from  "components/medangle/GlobalNavigation"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div >
      
      <GlobalNavigation/>
    
        <main style={{paddingTop:"20px",
      paddingBottom:"50px"}}>{children}</main>

      
      <GlobalFooter/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

//<Header siteTitle={data.site.siteMetadata?.title || `Title`} />

/*
 <GlobalNavigation/>
      <div className="column is-10-desktop is-12-mobile is-10-tablet center"
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>

      
      </div>
      <GlobalFooter/>
*/
